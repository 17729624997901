import {mapGetters, mapMutations} from "vuex";


export default {
  name: 'payment-status',
  computed: {
    ...mapGetters({
      paymentPopup: 'payment/paymentPopup',
    })
  },
  methods: {
    ...mapMutations({
      changeContentPaymentPopup: `payment/PAYMENT_POPUP_TEXT_CONTENT`,
    })
  },
  created(){
  },
  destroyed() {
    this.changeContentPaymentPopup({title: '', text: ''});
    this.$router.replace({'query': null});
  }
}
